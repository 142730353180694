<template>
  <div
    :class="{
      ['mx-auto border-b border-[#363636] ' + props.class]: true,
      'w-[90vw]': !props.inline
    }"
  ></div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
    inline?: boolean
  }>(),
  {
    class: '',
    inline: false
  }
)
</script>

<style scoped></style>
